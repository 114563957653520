import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,_vm._g(_vm._b({},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c(VCard,{staticClass:"content-wrapper"},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,{staticClass:"body"},[_vm._t("body",function(){return [_vm._v(" "+_vm._s(_vm.text)+" ")]})],2),_c(VCardActions,[_c('div',{staticClass:"footer"},[_vm._t("footer",function(){return [_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Ок ")])]})],2)]),_c(VOverlay,{attrs:{"absolute":true,"value":_vm.isLoading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }