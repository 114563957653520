<template>
  <v-skeleton-loader
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'RccSkeleton',

  inheritAttrs: false
}
</script>
