<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card class="content-wrapper">
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text class="body">
        <slot name="body">
          {{ text }}
        </slot>
      </v-card-text>

      <v-card-actions>
        <div class="footer">
          <slot name="footer">
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="$emit('input', false)"
            >
              Ок
            </v-btn>
          </slot>
        </div>
      </v-card-actions>

      <v-overlay
        :absolute="true"
        :value="isLoading"
      >
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RccDialog',

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;

  .v-card__title {
    word-break: break-word;
  }

  .body {
    flex-grow: 1;
    overflow-y: auto;
  }

  .footer {
    width: 100%;
    display: flex;
  }
}
</style>
