<template>
  <rcc-dialog
    title="Выберите необходимые регионы"
    v-bind="$attrs"
    :value="value"
    :is-loading="isLoading"
    v-on="$listeners"
    max-height="90vh"
  >
    <template #body>
      <div class="body">
        <div v-if="isLoading" class="skeleton-wrapper">
          <rcc-skeleton
            v-for="item in 10"
            :key="item"
            class="skeleton-item"
            type="list-item"
          />
        </div>

        <rcc-checkboxes-group
          v-else
          v-model="regionIds"
          :options="regions"
          choose-by="id"
          show-by="name"
          class="regions"
        />
      </div>
    </template>

    <template #footer>
      <v-spacer></v-spacer>

      <v-btn
        color="green darken-1"
        text
        @click="handleConfirmClick"
      >
        Ок
      </v-btn>

      <v-btn
        color="blue darken-1"
        text
        @click="handleCancelClick"
      >
        Отмена
      </v-btn>
    </template>
  </rcc-dialog>
</template>

<script>
import RccDialog from 'Components/dialogs/dialog'
import RccSkeleton from 'Components/ui/skeleton'
import RccCheckboxesGroup from 'Components/controls/rcc-checkboxes-group'

export default {
  name: 'RccRegionsSelectModal',

  inheritAttrs: false,

  components: {
    RccDialog,
    RccSkeleton,
    RccCheckboxesGroup
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },

    saveCallback: {
      type: Function,
      default: null
    },

    selectedRegions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      localColumns: [],
      isLoading: false,
      regions: [],
      regionIds: []
    }
  },

  watch: {
    value(isOpen) {
      if (isOpen) {
        this.regionIds = this.selectedRegions
      }
    }
  },

  mounted() {
    this.getRegions()
  },

  methods: {
    getRegions() {
      this.isLoading = true

      this.$apiMethods.regions.allList()
        .then(({ items }) => {
          this.regions = items
        })
        .finally(() => { this.isLoading = false })
    },

    handleCancelClick() {
      this.close()
    },

    async handleConfirmClick() {
      if (this.saveCallback) {
        this.saveCallback(this.regionIds)
      }
      this.close()
    },

    close() {
      this.$emit('input', false)
      this.regionIds = []
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  min-height: 300px;

  .skeleton-wrapper,
  .regions {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 5px;
  }

  .skeleton-item,
  ::v-deep .rcc-checkboxes-group__column {
    flex-grow: 1;
    width: calc(100% / 2 - 30px);
  }

  ::v-deep .v-skeleton-loader__list-item {
    height: 28px;
    padding: 0;
  }
}
</style>
